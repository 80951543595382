import { Languages } from '../common/constants/language';
import moment from 'moment';

export const environment = {
    production: false,
    protocol: 'https',
    host: 'eshop-api.morava.amcef.sk',
    basicAuthToken: 'bGlwbm9lc2hvcDpsaXBub2VzaG9w',
    mediaUrl: 'https://dolnimorava-images.s3.eu-central-1.amazonaws.com',
    debug: false,

    languages: [Languages.Czech, Languages.Polish, Languages.English],
    defaultLanguage: Languages.Czech,

    gtmIds: [],
    gtagIds: ['UA-183991217-1'],
    ga4Ids: ['G-9WFJDNP7FK', 'G-1TWTNPPPDY'],
    cookieBotDomainGroupId: 'f4dd23e8-99e5-4010-b131-8aae0ed26c66',

    project: 'morava',

    config: {
        timeZone: 'Europe/Prague',
        dualPrices: true,
        cartButtonUrl: '/shopping/discount',
        registrationCheckbox: false,

        aboutCard: false,
        registerCardNumber: true,
        registerVOPCheckbox: true,
        birthdayEditDisabled: true,
        showB2BAllCategories: true,
        downloadPrintTickets: false,

        addCardType: true,
        exchangeTicketCardBirthday: true,
        addExternalCard: false,
        addCardModalImage: undefined,
        allowShopOwnedCard: false,
        allowAdultExternal: false,
        removeCard: false,
        showTimeSlotRange: true,
        showDatePrices: true,
        showTicketOrigin: false,
        cardPatternMask: [
            ...[...(new Array(8)).keys()].map(() => /[A-Z0-9]/i),
            '-',
            ...[...(new Array(3)).keys()].map(() => /[A-Z0-9]/i),
            '-',
            ...[...(new Array(3)).keys()].map(() => /[A-Z0-9]/i),
        ],
        cardPattern: /^[A-Z0-9]{8}-[A-Z0-9]{3}-[A-Z0-9]{3}/g,
        validateLuhnNumber: false,

        userInfo: ['firstName', 'lastName', 'birthday'],

        paths: {
            kod: true,
            karta: true,
            tablet: true,
            registerRedirect: 'registrace',
        },

        priorityCountriesOptions: ['CZ', 'AT', 'DE', 'NL'],

        ticketSystem: 'axess',
        priceDecimals: 0,

        personalHeaderItems: ['cards', 'orders', 'discounts', 'settings'],

        cookiesLink: '/terms/cookies',

        productCountLimit: 200,
    },

    social: {
        facebook: 'https://www.facebook.com/dolnimorava/',
        instagram: 'https://www.instagram.com/dolnimorava/',
        youtube: 'https://www.youtube.com/channel/UCeLDEFtKvH_U6aYERtistwQ'
    },

    getPriceWithCurrency: (price) => {
        return `${price} Kč`;
    },

    getGlobalDisabledDates: (current) => {
        const d = moment(current).tz('Europe/Prague', true);
        const b = moment().tz('Europe/Prague').add(-1, 'day');

        return d.isSameOrBefore(b, 'day');
    },
};
